/* eslint-env browser */
// require('isomorphic-fetch');
// require('es6-promise').polyfill();
// require('@babel/polyfill');

const React     = require('react');
const { createRoot }  = require('react-dom/client');
const {
  BrowserRouter,
  Routes,
  Route
}               = require('react-router-dom');

const ErrorPage            = require('./components/_common/ErrorPage.jsx');
const Login                = require('./components/auth/pages/Login.jsx');
const RequestPasswordReset = require('./components/auth/pages/RequestPasswordReset.jsx');
const SetPassword          = require('./components/auth/pages/SetPassword.jsx');

const container = document.getElementById('spacerProLoginContainer');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <div className="spacer-pro">
      <Routes>

        {/* MAIN PAGES */}
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/requestPasswordReset" element={<RequestPasswordReset/>} />
        <Route exact path="/resetPassword/:key" element={<SetPassword/>} />
        <Route exact path="/activateAccount/:key" element={<SetPassword/>} />

        <Route render={() => <ErrorPage errorCode={404} />} />
      </Routes>
    </div>
  </BrowserRouter>
);
