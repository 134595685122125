const React = require("react");
const sharedPropTypes = require("../../../../shared/components/sharedPropTypes");
const AuthPage = require("./AuthPage.jsx");
const AuthForm = require("../form/AuthForm.jsx");
const pageManagerUtil = require("../../../util/pageManager");

function getCopyForPageType() {
  if (pageManagerUtil.onAuthPage("resetPassword")) {
    return {
      description: "Enter a new password.",
      submitText: "Set new password",
      passwordInputPlaceholder: "Choose a password",
    };
  } else if (pageManagerUtil.onAuthPage("activateAccount")) {
    return {
      description: "Welcome! ",
      submitText: "Activate my account",
      passwordInputPlaceholder: "Choose a password",
    };
  }

  return {};
}

function SetPassword(props) {
  const { description, submitText, passwordInputPlaceholder } =
    getCopyForPageType();

  return (
    <AuthPage>
      <AuthForm
        description={description}
        passwordInputPlaceholder={passwordInputPlaceholder}
        showPasswordInput
        userEmail={window.SERVER_DATA.user.email}
        submit={{
          url: `/setPassword/${props.params.key}`,
          text: submitText,
        }}
      />
    </AuthPage>
  );
}

SetPassword.propTypes = {
  match: sharedPropTypes.routeMatchShape.isRequired,
};

module.exports = SetPassword;
