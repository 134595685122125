const React = require("react");
const AuthPage = require("./AuthPage.jsx");
const AuthForm = require("../form/AuthForm.jsx");

function Login() {
  const loginError =
    window.SERVER_DATA.isValidUser && window.SERVER_DATA.isValidUser == 0
      ? true
      : false;

  return (
    <AuthPage>
      <AuthForm
        description="Welcome back."
        showPasswordInput
        loginError={loginError}
        submit={{
          url: "/login",
          text: "SSO Login",
        }}
      />
    </AuthPage>
  );
}

module.exports = Login;
