const React = require("react");
const AuthPage = require("./AuthPage.jsx");
const AuthForm = require("../form/AuthForm.jsx");

function RequestPasswordReset() {
  return (
    <AuthPage>
      <AuthForm
        submit={{
          url: "/requestPasswordReset",
          text: "Reset Password",
        }}
      />
    </AuthPage>
  );
}

module.exports = RequestPasswordReset;
