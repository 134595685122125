const React = require("react");
const PropTypes = require("prop-types");
const classnames = require("classnames");
const Link = require("react-router-dom").Link;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
    };

    this.updateScrollState = this.updateScrollState.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.updateScrollState);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.isScrolled !== nextState.isScrolled;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateScrollState);
  }

  updateScrollState() {
    this.setState({
      isScrolled: window.pageYOffset !== 0,
    });
  }

  render() {
    const classes = classnames("navbar is-fixed-top", {
      "has-shadow": this.state.isScrolled,
    });

    return (
      <div className="navbar is-fixed-top has-shadow">
        <div className="container">
          <div className="navbar-brand">
            <Link
              to={this.props.onAuthPage ? "/login" : "/"}
              className="navbar-item cbre-spacer-logo"
            >
              <img src="/svg/logos/spacer_pro_logo.svg" alt="CBRE Spacer Pro" />
            </Link>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  onAuthPage: PropTypes.bool,
};

Header.defaultProps = {
  children: [],
  onAuthPage: false,
};

module.exports = Header;
