const React     = require('react');
const PropTypes = require('prop-types');
const constants = require('wp-constants').spacerPro;
const TextField = require('@cbrebuild/blocks-react').Input.TextField;

function PasswordInput(props) {
  return (
    <TextField
      type="password"
      name="password"
      label={props.label}
      required
      value={props.value}
      onChange={evt => props.onChange(evt.target.value)}
      autoComplete="current-password"
      placeholder={props.placeholder}
      suffix={
        props.showCheckmarkAtMinLength && props.value.length >= constants.cms.MIN_USER_PASSWORD_LENGTH &&
          <img src="/svg/icons/checkmark-white-icon-in-green-circle-spacer-by-cbre.svg" alt="Checkmark icon in circle for Spacer by CBRE" />
      }
    />
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showCheckmarkAtMinLength: PropTypes.bool

};

PasswordInput.defaultProps = {
  label: 'Password',
  value: '',
  placeholder: '',
  showCheckmarkAtMinLength: false
};

module.exports = PasswordInput;
