const React = require("react");
const PropTypes = require("prop-types");
const requestUtil = require("../../../../shared/util/request");
const TextField = require("@cbrebuild/blocks-react").Input.TextField;
const PasswordInput = require("../../_common/form/PasswordInput.jsx");
const { useEffect } = require("react");
const ButtonSecondary = require("@cbrebuild/blocks-react/dist/buttons/ButtonSecondary");
const ButtonPrimary = require("@cbrebuild/blocks-react").Buttons.ButtonPrimary;

function AuthForm(props) {
  let loginError = props.loginError;

  const [errorlogin, setErrorlogin] = React.useState(props.loginError);

  const [authResponse, setAuthResponse] = React.useState({
    type: "",
    messsage: "",
  });

  function submit(evt) {
    evt.preventDefault();

    // clear auth response in case a previous error or success message is still visible
    setAuthResponse({ type: "", message: "" });

    const stateObject = { email, password, authResponse };
    return requestUtil
      .jsonPostWithCredentials(props.submit.url, stateObject)
      .then(
        (resp) => {
          if (resp.redirectTo)
            setTimeout(() => {
              window.location = resp.redirectTo;
            }, resp.waitBeforeRedirect || 0);
          if (resp.message) {
            setAuthResponse({
              type: "success",
              message: resp.message,
            });
          }
        },
        (error) => {
          setAuthResponse({
            type: "error",
            message: error.message,
          });
        }
      );
  }

  // const isPasswordSetOrResetPage = !!props.userEmail;

  let content;
  if (authResponse.type === "success") {
    content = <div>{authResponse.messsage}</div>;
  } else {
    content = (
      <div className="login-container">
        <form className="columns is-multiline"></form>
        <div
          className="columns is-full"
          style={{ marginTop: "0px", marginLeft: "24px" }}
        >
          <a href="/b2c/login" style={{ textDecoration: "none" }}>
            {loginError ? (
              <ButtonSecondary
                text="Sign in with CBRE SSO"
                style={{
                  width: "310px",
                  fontWeight: "400",
                }}
              />
            ) : (
              <ButtonPrimary
                text="Sign in with CBRE SSO"
                style={{
                  width: "310px",
                }}
              />
            )}
          </a>
        </div>
        <div style={{ width: "318px", color: "#A03530" }}>
          {loginError ? (
            <div
              className="blx-error form-error-message titlepage"
              style={{
                color: "#A03530",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "21px",
                marginLeft: "24px",
                marginTop: "10px",
              }}
            >
              Don't have an account? Please reach out to the team at{" "}
              <a
                href="mailto:spacersupport@cbre.com"
                class="underline error-text"
              >
                spacersupport@cbre.com
              </a>
            </div>
          ) : null}
        </div>
        <div style={{ marginTop: "20px", width: "318px", marginLeft: "24px" }}>
          <hr />
        </div>

        <div
          style={{
            marginTop: "10px",
            width: "318px",
            marginLeft: "24px",
            marginBottom: "10px",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "16px",
          }}
        >
          For more information about how we use the data you provide please
          refer to our{" "}
          <a
            href="https://www.cbre.com/about/privacy-policy"
            target="_blank"
            className="link"
          >
            Global Web Privacy and Cookie Policy
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="form-container form-container-narrow-width">
      <div className="form-description-login">{props.description}</div>
      <div>{content}</div>
    </div>
  );
}
AuthForm.propTypes = {
  userEmail: PropTypes.string,
  showPasswordInput: PropTypes.bool,
  submit: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
  passwordInputPlaceholder: PropTypes.string,
};

AuthForm.defaultProps = {
  userEmail: "",
  showPasswordInput: false,
  passwordInputPlaceholder: "",
};

module.exports = AuthForm;
