const React = require("react");
const PropTypes = require("prop-types");
const Header = require("../../_common/layout/Header.jsx");

function AuthPage(props) {
  return (
    <div>
      {/* <Header onAuthPage /> */}
      <div className="page-content containertitlepage ">
        <div className="auth-page-content-loginpage">
          <div className="auth-page-spacer-pro-title-page">
            <img
              style={{ fontFamily: "calibre" }}
              src="/svg/logos/spacer_pro_logo.svg"
              alt="CBRE Spacer Pro"
            ></img>
          </div>

          {props.children}
        </div>
      </div>
    </div>
  );
}

AuthPage.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = AuthPage;
